<template>
  <div class="PageBox">
    <NavBar :title="$t('wallet.default[0]')" :page-number="-1"/>
    <div class="ScrollBox Wallet" style="margin-top: 20px">
      <div class="Panel gradient-panel" style="display: flex; justify-content: space-around; align-items: center; height: 127px; color: #fff;">
        <div>
          <div>{{ $t("wallet.default[8]") }}</div>
          <div style="line-height: 48px; font-weight: bold;">{{ Number(UserInfo.balance).toFixed(2) }} (USDT)</div>
          <div>USDT</div>
        </div>
        <div>
          <div>{{ $t("hall.default[1]") }}</div>
          <div style="line-height: 48px; font-weight: bold;">{{Number(UserInfo.balance*InitData.usdtinfo.price).toFixed(2) }}</div>
          <div>≈</div>
        </div>
      </div>
      <div style="display:flex; width: 100%;text-align: center;margin-top: 20px;align-items: center;justify-content: space-evenly;">
          <div><van-button class="recharge-btn" style="" @click="gotoRe">{{ $t("wallet.default[1]") }}</van-button></div>
          <div><van-button class="recharge-btn" style="color: var(--main-bg-color); background-color: var(--bc-text-color-4) !important;" @click="gotoWithdraw">{{ $t("withdraw2[0]") }}</van-button></div>
      </div>

      <div class="wall">
        <div class="wall-item">
          <div style="font-size: 12px; color:var(--bc-text-color);">
            {{$t("wallet.default[5]")}}
          </div>
          <div style="display: flex; flex-direction: row; margin-top: 20px;">
            <div style="width: 90%; font-size:16px;">{{ Number(UserInfo.total_recharge).toFixed(2) }}</div>
            <div style="font-size:16px; color:var(--bc-text-color-0);">USDT</div>
          </div>
        </div>
        <div class="wall-item">
          <div style="font-size: 12px; color:var(--bc-text-color);">
            {{$t("wallet.default[6]")}}
          </div>
          <div style="display: flex; flex-direction: row; margin-top: 20px;">
            <div style="width: 90%; font-size:16px;">{{ Number(UserInfo.sell_numbers).toFixed(2) }}</div>
            <div style="font-size:16px; color:var(--bc-text-color-0);">USDT</div>
          </div>
        </div>
        <div class="wall-item">
          <div style="font-size: 12px; color:var(--bc-text-color);">
            {{$t("wallet.default[7]")}}
          </div>
          <div style="display: flex; flex-direction: row; margin-top: 20px;">
            <div style="width: 90%; font-size:16px;">{{ Number(UserInfo.total_commission).toFixed(2) }}</div>
            <div style="font-size:16px; color:var(--bc-text-color-0);">USDT</div>
          </div>
        </div>
        <div class="wall-item">
          <div style="font-size: 12px; color:var(--bc-text-color);">
            {{$t("wallet.default[24]")}}
          </div>
          <div style="display: flex; flex-direction: row; margin-top: 20px;">
            <div style="width: 90%; font-size:16px;">{{ Number(UserInfo.total_withdrawals).toFixed(2) }}</div>
            <div style="font-size:16px; color:var(--bc-text-color-0);">USDT</div>
          </div>
        </div>
      </div>
    </div>
    <Loading />
  </div>
</template>

<script>
import incomplete from "../../components/tanchuang/Incomplete.vue";
import comiss from "../../components/tanchuang/commissionTasks.vue";
export default {
  name: "Wallet",
  components: { },
  props: ["walletType"],
  data() {
    return {
      tc_type: "111", //弹窗类型
      show_lucky: false, //显示领取幸运包弹窗
      postData: {},
      showPayWord: false,
      listData: [],
      isLoad: false,
      isFinished: false,
      isRefresh: false,
      pageNo: 1,
      tabsState: 1,
      tabsIndex: 0,
      def_info: {},
      taskTabs: [
        // 充值
        {
          type: 1,
          text: this.$t("wallet.default[3]"),
        },
        // {type: 2, text: '转账记录'},
        {
          type: 3,
          text: this.$t("wallet.default[4]"),
        },
      ],
      showTurn: false, //提现弹窗
      showDraw: false,
      showPicker: false,
      selectBank: "",
      isSubmit: false,
      turnData: {
        username: "",
        turn_money: "",
        drawword: "",
      },
      drawData: {
        draw_type: "bank",
        user_bank_id: "",
        submit_key: "",
        draw_money: 100,
        // phone: '',
        email: "",
        // ifsc: '',
        drawword: "",
      },
      popUpWindows: false, //判断是否绑定银行卡
      currency_name: JSON.parse(localStorage.getItem("InitData")).setting
          .currency_name, //货币符号s
    };
  },
  computed: {
    cardList() {
      let data = this.$store.state.BankCardList.map(
          (item) =>
              item.bank_name +
              " " +
              item.card_no.replace(/^(.{4}).*(.{4})$/, "$1 **** $2")
      );
      // if(this.UserInfo.alipay){
      //   data.unshift(this.$t('wallet.default[7]')+this.UserInfo.alipay.replace(/^(.{4}).*(.{4})$/, '$1 **** $2'))
      // }
      return data;
    },
  },
  watch: {
    $route() {
      if (this.walletType) {
        this.tabsIndex = this.taskTabs.findIndex(
            (item) => item.type == this.walletType
        );
        this.tabsState = this.walletType;
      } else {
        this.tabsState = 1;
        this.tabsIndex = 0;
      }
      this.getListData("init");
    },
  },
  created() {
    this.$Model.GetUserInfo();
  },
  mounted() {
  },
  activated() {},
  destroyed() {},
  methods: {
    // 跳转充值
    toRecharge() {
      //整合传统充值和币充值:
      this.$router.push("/user/recharge");

    },
    openInfo(id) {
      if (this.tabsIndex == 0) {
        this.$router.push(`/user/recharge/${id}`);
      }
    },
    onConfirm(value, index) {
      this.drawData.user_bank_id = this.BankCardList[index].id;
      this.drawData.bank_code = this.BankCardList[index].bank_code;
      this.selectBank = value;
      this.showPicker = false;
    },
    confirmSubmit(submit_key) {
      this.isSubmit = true;
      if (submit_key) this.drawData.submit_key = submit_key;

    },
    onLoad() {
      // this.getListData("load");
    },
    changeTabs(index) {
      this.tabsState = this.taskTabs[index].type;
      this.getListData("init");
      // this.$router.push(`/user/wallet/${this.tabsState}`)
    },
    gotoRe(){
      this.$router.push("/user/mixrecharge");
    },
    gotoWithdraw(){
      this.$router.push("/user/withdraw");
    },
    getListData(type) {
      this.isLoad = true;
      this.isRefresh = false;
      if (type == "load") {
        this.pageNo += 1;
      } else {
        this.pageNo = 1;
        this.isFinished = false;
      }
      if (this.tabsIndex == 0) {
        this.$Model.GetRechargeRecord(
            {
              state: 0,
              page_no: this.pageNo,
            },
            (data) => {
              this.isLoad = false;
              if (data.code == 1) {
                if (type == "load") {
                  this.listData[this.tabsIndex] = this.listData[this.tabsIndex]
                      ? this.listData[this.tabsIndex].concat(data.info)
                      : data.info;
                  console.log(this.listData[this.tabsIndex]);
                  /* this.listData[this.tabsIndex].concat(data.info); */
                } else {
                  this.listData[this.tabsIndex] = data.info;
                }
                if (this.pageNo == data.data_total_page) {
                  this.isFinished = true;
                } else {
                  this.isFinished = false;
                }
              } else {
                this.listData[this.tabsIndex] = "";
                this.isFinished = true;
              }
            }
        );
      } else if (this.tabsIndex == 1) {
        this.$Model.GetDrawRecord(
            {
              state: 0,
              page_no: this.pageNo,
            },
            (data) => {
              this.isLoad = false;
              if (data.code == 1) {
                if (type == "load") {
                  this.listData[this.tabsIndex] = this.listData[
                      this.tabsIndex
                      ].concat(data.info);
                } else {
                  this.listData[this.tabsIndex] = data.info;
                }
                if (this.pageNo == data.data_total_page) {
                  this.isFinished = true;
                } else {
                  this.isFinished = false;
                }
              } else {
                this.listData[this.tabsIndex] = "";
                this.isFinished = true;
              }
            }
        );
      } else {
        this.$Model.FundDetails(
            {
              trade_type: 11,
              page_no: this.pageNo,
            },
            (data) => {
              this.isLoad = false;
              if (data.code == 1) {
                if (type == "load") {
                  this.listData[this.tabsIndex] = this.listData[
                      this.tabsIndex
                      ].concat(data.list);
                } else {
                  this.listData[this.tabsIndex] = data.list;
                }
                if (this.pageNo == data.data_total_page) {
                  this.isFinished = true;
                } else {
                  this.isFinished = false;
                }
              } else {
                this.listData[this.tabsIndex] = "";
                this.isFinished = true;
              }
            }
        );
      }
    },
    onRefresh() {
      // this.getListData("init");
    },
  },
};
</script>
<style scoped>
.PageBox >>> .van-nav-bar__title{
  font-size: 18px;
}
.PageBox >>> .van-nav-bar .van-nav-bar__arrow {
  font-size: 20px;
}

.PageBox {
  overflow: auto;

}

.van-pull-refresh {
  min-height: calc(100vh - 219px);
  /* 数据框 */
}

.Menu2 {
  width: 100%;
}


.Wallet .tool .van-cell.balance {
  /*background-color: #151d31;*/

  border: none;
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  padding: 6px 10px;
  line-height: 1;
  position: relative;
  margin-bottom: 10px;
  z-index: 3;
  color: #4e51bf;
}


.Wallet .tool {
  margin-bottom: 40px;
  padding: 0 6px;
  position: relative;
  z-index: 2;
  top: 20px;
}

.Wallet .tool::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -80%;
}

.Wallet .tool > div {
  background-color:  var(--panel_color);
  border-radius: 10px;
  height: 150px;
  overflow: hidden;
  z-index: 99;
  padding: 0 5px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.Wallet .tool a {
  flex: 1;
  text-align: center;
  font-size: 16px;
  color: #4087f1;
  line-height: 25px;
  font-weight: 600;
}

.Wallet .tool a + a {
  border-left: 1px #4087f1 solid;
}

.van-tabs {
  margin-top: 10px;
}

.FundItem >>> .van-cell {
  color: #000;
}

.van-tabs >>> .van-tabs__wrap {
  height: 36px;
  width: 340px;
  margin: auto;
}

.van-tabs {
  padding: 0;
}
.van-tabs >>> .van-tabs__wrap .van-tab:nth-of-type(1) {
  margin-right: 5px;
}

.van-tabs >>> .van-tabs__wrap .van-tab:nth-of-type(2) {
  margin-left: 5px;
}

.van-tabs >>> .van-tabs__nav--card {
  overflow: hidden;
  height: 36px;
  margin: 0;
}

.popUpWindows {
  width: 50%;
  min-width: 280px;
  max-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  border-radius: 4px;
  background-color: #fff;
}

.popUpWindows .warmHint {
  display: block;
  width: 50px;
  height: 50px;
  margin: 30px auto 0 auto;
}

.popUpWindows .title1 {
  text-align: center;
  color: #0f25fe;
  font-size: 22px;
  line-height: 60px;
}

.popUpWindows .content {
  width: 100%;
  padding: 15px 20px 30px 20px;
  color: #000;
  text-align: center;
}

.popUpWindows .btn {
  display: flex;
  padding: 30px 20% 30px 20%;
  justify-content: space-between;
  color: #fff;
}

.popUpWindows .btn > p {
  line-height: 30px;
  text-align: center;
  background-color: rgb(211, 211, 211);
  width: 44%;
  border-radius: 4px;
}

.popUpWindows .btn > p:nth-child(2) {
  background-color: rgb(128, 128, 255);
}

.masking {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(90, 90, 90, 0.6);
  z-index: 3;
}

.balancetitle {
  color: #000;
  font-size: 16px;
  margin: auto;
}

.PageBox >>> .van-tabs__line {
  height: 0;
  /* 删掉跟随下划线 */
}

.Wallet .tool .van-cell {
  border-radius: 25px;
  font-size: 16px;
  background-color: #fff;
  color: #000;
  text-align: center;
  position: absolute;
  width: 100px;
  height: 40px;
}

.PageBox >>> .van-tab__text--ellipsis {
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  height: 100%;
}

/* .PageBox>>>.van-tab--active .c1 {
        background-image: linear-gradient(to bottom, #fd94a6, #b5b4f9);
        width: 155px;
        height: 34px;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 20px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        padding: 2px;
    }

    .PageBox>>>.van-tab--active .c1 .c2 {
        background: #4e51bf;
        height: 28px;
        border-radius: 20px;
        margin-left: 1px;
        margin-top: 1.5px;
    } */
.c1 {
  padding: 3px;
  box-sizing: border-box;
  height: 100%;
}
.c1 .c2 {
  color: #000;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  font-size: 14px;
  text-align: center;
}
.tabActive {
  background-image: linear-gradient(to bottom, #fd94a6, #b5b4f9);
  border-radius: 20px;
}
.tabActive .c2 {
  background: #4e51bf;
  color: #fff;
  border-radius: 18px;
  font-size: 15px;
}
.record {
  color: #4e51bf;
  font-weight: 500;
  font-size: 15px;
}
.FundItem >>> .van-cell__title > div {
  display: flex;
  justify-content: space-between;
}
.FundItem >>> .van-cell__title > div > span:nth-child(2) {
  font-size: 16px;
}
.FundItem >>> .van-cell__title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding-bottom: 10px;
}
.van-popup >>> .van-action-sheet__content .van-button {
  background: none;
  background-color: #4e51bf;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.giftBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 259px;
  height: 312px;
  border-radius: 20px;
  border: solid 1px rgba(169, 169, 169, 0.4);
  background: #ffffff;
}
.giftBox > .top_title {
  position: absolute;
  top: -6%;
  width: 90%;
  min-height: 38px;
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  background-image: url("../../assets/img/gongxi.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.giftBox > .receiveTask {
  margin-top: 10%;
  color: #0e0e0e;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
}
.giftBox > .img {
  margin-top: 15%;
}
.giftBox > .desc {
  margin-top: 8%;
  color: #0e0e0e;

  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.giftBox > .button > div {
  text-align: center;
  width: 170px;
  height: 33px;
  margin-top: 10%;
  color: #fff;
  line-height: 33px;

  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;
  background: #5e59e9;
}
::v-deep .van-overlay {
  z-index: 9;
}

/* 提现告知有未完成任务的弹窗 */
.wrapper > .poPup {
  width: 350px;
  height: max-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.wrapper > .poPup > .desc {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
  width: 263px;
  height: 272px;
  border: dashed 1px #e18e4a;
  border-radius: 10px;
  background: linear-gradient(180deg, #ffe4b5, #ffe4b5, #f6c791);
  box-shadow: inset 0px 10px 13px #eb8f43;
}

.wrapper > .poPup > .desc > div > .money_img {
  position: absolute;
  top: 160px;
  width: 181px;
  height: 75.98px;
  background: url("../../assets/img/wt.png") no-repeat;
  background-size: cover;
  border-radius: 5px;
  overflow-x: hidden;
}
/* .wrapper > .poPup > .desc > div > .money_img > div:last-child {
  width: 100%;
  height: 100%;
  background-color: rgba(38, 36, 39, 0.7);
} */
.wrapper > .poPup > .desc > div:last-child {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  width: 243.31px;
  height: 251.38px;
  background: linear-gradient(180deg, #ffffff, #ffffff, #ffe9d8);
  box-shadow: 0px 0.62px 4.96px #926d47;
}
.wrapper > .poPup > .desc > div > .title {
  position: absolute;
  top: -8%;
  width: 210px;
  height: 40px;
  color: #9e5029;

  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  text-align: center;
  border-radius: 10px;
  background: linear-gradient(90deg, #f5cd87, #f0b664);
  box-shadow: inset 0px 1px 1px #e6a64e;
  overflow: hidden;
}

.wrapper > .poPup > .desc > div > .text {
  padding: 35px 14px;
  color: #9e5029;
  font-size: 18px;

}

.wrapper > .poPup > .buttom {
  display: flex;
  margin-top: -25px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  position: relative;
  width: 321px;
  height: 212px;
}
.wrapper > .poPup > .buttom > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 247px;
  height: 58px;
  border-radius: 12px;
  border: solid #feeac5;
  background: linear-gradient(180deg, #edb45d, #eab662);
}
.wrapper > .poPup > .buttom > div:nth-child(2) {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 247px;
  height: 45px;
  border-radius: 12px;
  border: solid #feeac5;
  background: linear-gradient(180deg, #edb45d, #eab662);
}
.wrapper > .poPup > .buttom > div:nth-child(2) > div {
  width: 237px;
  height: 35px;
  border-radius: 14px;
  border: solid 1px #d94a11;
  text-align: center;
  color: #ffffff;

  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  background: linear-gradient(180deg, #ffaa5a, #ff5a00);
}
.wrapper > .poPup > .buttom > div:first-child > div {
  width: 237px;
  height: 48px;
  border-radius: 14px;
  border: solid 1px #d94a11;
  text-align: center;
  color: #ffffff;

  font-size: 24.82805px;
  font-weight: 400;
  line-height: 48px;
  background: linear-gradient(180deg, #ffaa5a, #ff5a00);
}
.wrapper > .poPup > .buttom > div:last-child {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 247px;
  height: 58px;
  border-radius: 12px;
  border: solid #feeac5;
  text-align: center;
  color: #ffffff;

  font-size: 24.82805px;
  font-weight: 400;
  /* line-height: 58px; */
  background: linear-gradient(180deg, #edb45d, #eab662);
}

.wrapper > .poPup > .buttom {
  display: flex;
  margin-top: -25px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  position: relative;
  width: 321px;
  height: 212px;
}
.btn {
  width: 85%;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #5652FD;
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 15px 15px 30px;
}

.recharge-btn {
  background: #6bb853 !important;
  width: 96px;height: 28px;
}

.wall {
  display: grid;
  grid-template-columns: 50% 50% ;
  padding: 18px;
  flex-wrap: wrap;

  .wall-item {
    border: 2px solid #404040;
    border-radius: 10px;
    padding: 10px; width: 160px;
    margin-bottom: 20px;
  }
}

</style>
